.neusta-image-teaser {
    border-radius: 6px;
    overflow: hidden;
    position: relative;

    @media only screen and (min-width: 1024px) {
        display: flex;

        img {
            width: 100% !important;
        }

        .content {
            justify-content: flex-end;
            display: flex;
            flex-direction: column;

        }
    }
}

.o-layout__item > .neusta-image-teaser {
    display: block;
}

.neusta-image-teaser {
    &.dark-grey .content {
        background-color: #646363;
    }

    &.dark-beige .content {
        background-color: #AA9678;
    }

    &.medium-beige .content {
        background-color: #E9E4DD;

        .headline {
            color: #646363;
        }

        .title {
            color: #646363;
        }
    }

    &.light-beige .content {
        background-color: #F8F7F2;

        .headline {
            color: #646363;
        }

        .title {
            color: #646363;
        }
    }

    &.corporate-red .content {
        background-color: #E2001A;
    }

    &.light-orange .content {
        background-color: #F9B234;
    }

    &.sunny-yellow .content {
        background-color: #FFCC00;
    }

    &.dark-apple-green .content {
        background-color: #7AA62B;
    }

    &.medium-turquoise-green .content {
        background-color: #178E76;
    }

    &.dark-turquoise-green .content {
        background-color: #004D4D;
    }

    &.medium-red .content {
        background-color: #D51031;
    }

    &.dark-purple .content {
        background-color: #752A56;
    }

    &.blue .content {
        background-color: #0067AB;
    }

    &.dark-royal-blue .content {
        background-color: #2A2081;
    }

    &.dark-denim-blue .content {
        background-color: #004564;
    }
}

.neusta-image-teaser > a {
    display: block;
    text-decoration: none;
    line-height: 0;
}

.neusta-image-teaser .content {
    padding: spacing(m);
    position: relative;
    width: 100%;

    @media only screen and (min-width: 1025px) {
        padding: spacing(xl);
    }
}

.neusta-image-teaser .headline {
    color: #fff;
    font-family: Arial, sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    display: block;
    margin-bottom: 8px;

    @media only screen and (min-width: 1025px) {
        margin-bottom: 12px;
    }
}

.neusta-image-teaser .title {
    color: #fff;
    font-family: NorthernSoul, sans-serif;
    font-size: 32px;
    letter-spacing: 0;
    line-height: 40px;
    display: block;
    margin-bottom: 20px;

    @media only screen and (min-width: 1025px) {
        font-size: 50px;
        margin-bottom: 28px;
        line-height: 54px;
    }
}

.neusta-image-teaser .btn.secondary {
    border-radius: 4px;
    background-color: #fff;
    color: $gray;
    font-family: Arial, sans-serif;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 15px;
    padding-right: 15px;
    appearance: none;
    border: none;
    white-space: nowrap;
}

.neusta-image-teaser .btn:hover {
    color: color(base);
    border: none;
}

@media only screen and (min-width: 1025px) {
    .neusta-image-teaser .btn.secondary {
        width: auto;
    }
}

.page-products {
    .neusta-image-teaser {
        picture {
            width: 100%;

            img {
                width: 100% !important;
            }
        }

        .content {
            width: 100%;
        }

        @media only screen and (min-width : 1024px) {
            picture {
                width: 60%;

                img {
                    width: 100% !important;
                }
            }

            .content {
                width: 40%;
            }
        }
    }
}

//.neusta-image-teaser .media-wrapper {
//    height: 335px;
//    width: 100%;
//    position: relative;
//
//    @media only screen and (min-width: 1024px) {
//        height: 400px;
//    }
//
//    img {
//        width: 100%;
//        height: 100%;
//        position: absolute;
//        object-fit: cover;
//    }
//}
